/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function eventDateFormatter(profile) {
  //profile is the entire thing so we want to reference the following
  // startDate, endDate, checkInTime, checkOutTime
  // profile[key] is how you access the value
  // ie., profile[startDate]
  return JSON.stringify(profile[startDate], null, 4)

  if (profile[startDate] == profile[endDate]) {
    return "Date: " + JSON.stringify(profile[startDate], null, 4);
  } else {
    return "Dates: " + profile[startDate] + " - " + profile[endDate];
  }
}

export function listNames(entityList) {
    if(!entityList) {
      return null;
    }
    let names = [];
    entityList.forEach(element => names.push(element.name));
    return names;
}
